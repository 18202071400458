import React from "react";
import { Container, Row, Col } from "reactstrap";
import PageLayout from "../components/PageLayout";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faFileSignature,
  faThumbsUp,
  faComments,
  faHandHoldingUsd,
} from "@fortawesome/free-solid-svg-icons";
import { faWhatsappSquare } from "@fortawesome/free-brands-svg-icons";

import PrestamosImg from "../components/Images/home-img";
import JaimeOrozcoImg from "../components/Images/jaime-orozco-img";

const PrestamosPage = () => (
  <PageLayout>
    <Container className="page-layout mt-5">
      <Row>
        <Col>
          <h3 className="text-center text-uppercase">
            Pr&eacute;stamos hipotecarios
          </h3>
          <hr className="title-line" />
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <PrestamosImg />
        </Col>
        <Col>
          <ul className="welcome-list list-unstyled mt-4">
            <li className="d-flex mb-3">
              <span className="mr-2">
                <FontAwesomeIcon icon={faCheck} color="#BCA068" />
              </span>
              <span>Para libre inversi&oacute;n.</span>
            </li>
            <li className="d-flex mb-3">
              <span className="mr-2">
                <FontAwesomeIcon icon={faCheck} color="#BCA068" />
              </span>
              <span>Obtenga su pr&eacute;stamo en menos de 8 d&iacute;as.</span>
            </li>
            <li className="d-flex mb-3">
              <span className="mr-2">
                <FontAwesomeIcon icon={faCheck} color="#BCA068" />
              </span>
              <span>
                Tasa preferencial por debajo de entidades financieras.
              </span>
            </li>
            <li className="d-flex mb-3">
              <span className="mr-2">
                <FontAwesomeIcon icon={faCheck} color="#BCA068" />
              </span>
              <span>
                Nos especializamos en la intermediaci&oacute;n de
                pr&eacute;stamos hipotecarios sobre bienes ra&iacute;ces.
              </span>
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4 className="text-center mt-5">
            Su pr&eacute;stamo en cuatro pasos
          </h4>
          <ul className="welcome-list list-unstyled mt-4 text-center">
            <li className="mb-3">
              <FontAwesomeIcon
                icon={faComments}
                className="w-100 text-center mb-3"
                size="3x"
                color="#BCA068"
                fixedWidth
              />
              <h5>1.&nbsp; Solicitar cr&eacute;dito</h5>
              <p>
                Cont&aacute;ctenos a trav&eacute;s de nuestros canales para
                solicitar el formulario de cr&eacute;dito.
              </p>
            </li>
            <li className="mb-3">
              <FontAwesomeIcon
                icon={faFileSignature}
                className="w-100 text-center mb-3"
                size="3x"
                color="#BCA068"
                fixedWidth
              />
              <h5>2.&nbsp; Estudio de t&iacute;tulos.</h5>
              <p>
                Nuestro asesor se pondr&aacute; en contacto con usted para
                brindarle m&aacute;s informaci&oacute;n.
              </p>
            </li>
            <li className="mb-3">
              <FontAwesomeIcon
                icon={faThumbsUp}
                className="w-100 text-center mb-3"
                size="3x"
                color="#BCA068"
                fixedWidth
              />
              <h5>3.&nbsp; Aprobaci&oacute;n del cr&eacute;dito</h5>
              <p>En menos de dos (2) días obtenga su pr&eacute;stamo.</p>
            </li>
            <li className="mb-3">
              <FontAwesomeIcon
                icon={faHandHoldingUsd}
                className="w-100 text-center mb-3"
                size="3x"
                color="#BCA068"
                fixedWidth
              />
              <h5>4.&nbsp; Desembolso del cr&eacute;dito</h5>
              <p>
                Su pr&eacute;stamo sera desembolsado en el transcurso de diez
                (10) d&iacute;as h&aacute;biles.
              </p>
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col xs="12" lg={{ size: 10, offset: 1 }}>
          <h3 className="text-center mt-5">Requisitos</h3>
          <hr className="title-line" />
          <p>
            Para solicitar pr&eacute;stamos hipotecarios tenga encuenta los
            siguientes requisitos.
          </p>
          <ul className="welcome-list">
            <li>Certificado de libertad libre de todo grav&aacute;men.</li>
            <li>Aplica &uacute;nicamente para personas naturales.</li>
            <li>
              Se presta m&aacute;ximo el 33% del valor comercial del inmueble.
            </li>
            <li>Pr&eacute;stamo a 1 año prorrogable.</li>
            <li>Inmuebles urbanos en Bogot&aacute; y Sabana.</li>
          </ul>
        </Col>
        <Col lg={{ size: 10, offset: 1 }}>
          <h3 className="text-center mt-5">¿Necesita ayuda?</h3>
          <hr className="title-line" />
          <p>Estamos aqu&iacute; para ayudarlo con lo que necesite.</p>
          <p>Comun&iacute;quese directamente con nuestro asesor.</p>
          <div className="d-flex flex-column text-center">
            <JaimeOrozcoImg />
            <h4 className="mt-3">Dr. Jaime Quintero Orozco</h4>
            <p>
              <a href="https://wa.me/573102046791">
                <FontAwesomeIcon
                  icon={faWhatsappSquare}
                  size="5x"
                  color="#34af23"
                  fixedWidth
                />
              </a>
            </p>
          </div>
          <div className="p-4 text-center">
            <a href={`mailto:${process.env.GATSBY_COMERCIAL_EMAIL}`}>
              {process.env.GATSBY_COMERCIAL_EMAIL}
            </a>
            <a
              href={`tel:${process.env.GATSBY_COMERCIAL_CELLPHONE}`}
              className="d-block mt-3"
            >
              {process.env.GATSBY_COMERCIAL_CELLPHONE_DISPLAY}
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  </PageLayout>
);

export default PrestamosPage;
