import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const JaimeOrozcoImg = () => (
  <StaticImage
    src="../../images/jaime-orozco.png"
    alt="Jaime Orozco"
    layout="fixed"
    height={184}
  />
);

export default JaimeOrozcoImg;
